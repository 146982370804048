import { useEffect, RefObject } from 'react'

type Event = MouseEvent | TouchEvent | KeyboardEvent

export function useClickOutside<T extends HTMLElement = HTMLElement>(
  ref: RefObject<T>,
  handler: (event: Event) => void
) {
  useEffect(() => {
    const listener = (event: Event) => {
      const el = ref?.current
      if (!el || el.contains((event?.target as Node) || null)) {
        return
      }

      handler(event)
    }

    const escapeListener = (event: KeyboardEvent) => {
      if (event.key === 'Escape') {
        handler(event as unknown as Event);
      }
    };

    document.addEventListener('mousedown', listener)
    document.addEventListener('touchstart', listener)
    document.addEventListener('keyup', escapeListener);

    return () => {
      document.removeEventListener('mousedown', listener)
      document.removeEventListener('touchstart', listener)
      document.removeEventListener('keyup', escapeListener);
    }
  }, [ref, handler])
}
