import { useState, useRef, useEffect } from 'react'
import { useClickOutside } from '../../hooks/use-click-outside'

const qualityConfig = [
    { value: 'good', label: 'Buena', color: '#40c9ac' },
    { value: 'neutral', label: 'Neutral', color: '#ACB8C0' },
    { value: 'bad', label: 'Mala', color: '#ff6b6b' },
]

interface LeadQualitySelectorProps {
    className?: string,
    value?: string,
    onSelect: (value: string) => any
}

export default function LeadQualitySelector({ 
    className,
    value,
    onSelect
}: LeadQualitySelectorProps) {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedQuality, setSelectedQuality] = useState<typeof qualityConfig[0]>();
    const dropdownRef = useRef<HTMLDivElement>(null);

    useClickOutside(dropdownRef, () => setIsOpen(false))

    const toggleDropdown = () => setIsOpen(!isOpen)

    const handleSelect = (quality: typeof qualityConfig[0]) => {
        setSelectedQuality(quality);
        setIsOpen(false)
        onSelect(quality.value)
    };

    useEffect(() => {
        setSelectedQuality(qualityConfig.find(q => q.value === value));
    }, [value]);

    return (
        <div className={`relative min-w-[120px] ${className || ''}`} ref={dropdownRef}>
            <button
                onClick={toggleDropdown}
                className={`w-full h-[30px] border border-transparent  ${isOpen ? 'rounded-tr-xl rounded-tl-xl bg-[#1a2238]' : 'rounded-xl bg-[#212944] hover:bg-[#1a2238]'} px-4 flex items-center justify-between`}
                aria-haspopup="listbox"
                aria-expanded={isOpen}
            >
                {(isOpen || !selectedQuality) && <span className='text-[#ACB8C0]'>Calidad</span>}
                {!isOpen && selectedQuality && <span style={{ color: selectedQuality.color }}>
                    <GaugeIcon />
                    <span className='align-middle' style={{ color: selectedQuality.color }}>{selectedQuality.label}</span>
                </span>}
            </button>
            {isOpen && (
                <div
                    className="-mt-[1px] absolute overflow-hidden top-full left-0 w-full bg-[#1a2238] shadow-lg z-10 rounded-br-xl rounded-bl-xl border-transparent border-l border-b border-r"
                    role="listbox"
                >
                    {qualityConfig.map((quality) => (
                        <button
                            key={quality.value}
                            onClick={() => handleSelect(quality)}
                            className="w-full px-3 py-2 text-left hover:bg-[#262b3d] flex items-center"
                            role="option"
                            style={{ color: quality.color }}
                            aria-selected={quality.value === selectedQuality?.value}
                        >
                            <GaugeIcon />
                            <span className='align-middle' style={{ color: quality.color }}>{quality.label}</span>
                        </button>
                    ))}
                </div>
            )}
        </div>
    )
}

function GaugeIcon() {
    return (
        <svg className='inline-block mr-2 align-middle' xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="m12 14 4-4" /><path d="M3.34 19a10 10 0 1 1 17.32 0" /></svg>
    )
}