import { InputProps } from "../../interfaces";
import { BsSearch } from "react-icons/bs";
import './Search.css'

export const Search: React.FC<InputProps> = ({
  searchRef,
  timeout,
  refetch,
  expandSearch,
  openSearch,
}) => {

  const openSearchInput = () => {
    expandSearch(true)
    window.innerWidth >= 768 && searchRef.current?.focus()
  }

  return (
      <div className={`${openSearch ? 'active' : ''} searchBtn`} onClick={() => openSearchInput()}>
        <span>
          <BsSearch className="text-[#576080]"/>
        </span>
        <input
          type="text"
          placeholder="Buscar por nombre o teléfono"
          ref={searchRef}
          className="bg-transparent text-white"
          onKeyUp={() => {
            if (timeout) clearTimeout(timeout);
            timeout = setTimeout(refetch, 500);
          }}
          id="search"
        />
      </div>
  );
};
