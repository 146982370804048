import { useContext, useEffect, useState } from "react";
import { WorkflowPanel } from "../components/Workflows/WorkflowPanel";
import { WorkflowSidebar } from "../components/Workflows/WorkflowSidebar";
import userContext from "../utils/userContext";
import {
  deleteWorkflow,
  duplicateWorkflow,
  editWorkflow,
  getWorkflow,
  getWorkflows,
} from "../services/workflows.service";
import { EditWorkflow, Workflow } from "../interfaces/workflow.interface";
import { context } from "../interfaces";
import OpenModal from "../components/OpenModal";
import { NewWorkflow } from "../components/Workflows/NewWorkflow";
import { Skeleton } from "@mui/material";
import FullScreenLoader from "../components/FullScreenLoader";
import { toast, ToastContainer } from "react-toastify";

const Workflows: React.FC = () => {
  const [workflows, setWorkflows] = useState([] as any);
  const [selectedWorkflow, setSelectedWorkflow] = useState<Workflow | null>(
    null
  );
  const [isEditMode, setIsEditMode] = useState(false);
  const [isCreateMode, setIsCreateMode] = useState(false);

  const { isModalOpen, setModalOpen, agent } = useContext(
    userContext
  ) as context;
  const [modalContent, setModalContent] = useState({
    content: "",
    btnValue: "",
    fn: () => {},
  });

  const [isLoading, setIsLoading] = useState(false);
  const [showLoader, setShowLoader] = useState(false);

  const fetchWorkflows = async (defaultWorkflowId: string | null = null) => {
    const _workflows = (await getWorkflows(agent.company)).data;
    await setWorkflows(_workflows.reverse());
    handleSelect(defaultWorkflowId || _workflows[0]._id);
  };

  const handleSelect = async (workflowId: string) => {
    setIsLoading(true);
    const workflow = await getWorkflow(workflowId);
    setSelectedWorkflow(workflow.data);
    setIsLoading(false);
  };

  const openModal = (
    content: string,
    btnValue: string,
    fn: () => void,
    value: string
  ) => {
    setModalContent({ content, btnValue, fn });
    setModalOpen({ ...isModalOpen, [value]: true });
  };

  const closeModal = (value: string) => {
    setModalOpen({ ...isModalOpen, [value]: false });
  };

  const handleDelete = () => {
    openModal(
      "¿Está seguro que desea eliminar el workflow?",
      "Confirmar",
      confirmDelete,
      "delete"
    );
  };

  const confirmDelete = async () => {
    closeModal("delete");
    setShowLoader(true);
    deleteWorkflow(selectedWorkflow!._id)
      .then((res) => {
        if (res.status === 200) {
          toast.success("Workflow eliminado (" + selectedWorkflow!.title + ")");
          setWorkflows((prev: any) => {
            return prev.filter(
              (workflow: any) => workflow._id !== selectedWorkflow!._id
            );
          });
          setSelectedWorkflow(null);
        }
      })
      .catch((err) => {
        toast.error(err.response.data.detail.message);
      })
      .finally(() => setShowLoader(false));
  };

  const handleDuplicate = () => {
    openModal(
      "¿Está seguro que desea duplicar el workflow?",
      "Confirmar",
      confirmDuplicate,
      "duplicate"
    );
  };

  const confirmDuplicate = () => {
    setShowLoader(true);
    duplicateWorkflow(selectedWorkflow).then((res) => {
      toast.success("Workflow duplicado");
      fetchWorkflows();
      setShowLoader(false);
    });
    setModalOpen({ ...isModalOpen, duplicate: false });
  };

  const handleSave = async (workflow: EditWorkflow) => {
    setShowLoader(true);
    const res = await editWorkflow(workflow);
    if (res.status === 200) {
      fetchWorkflows(workflow._id);
      toast.success("Workflow editado");
    } else {
      toast.error(res.data.detail.message);
    }
    setShowLoader(false);
  };

  const update = () => {
    setWorkflows([]);
    fetchWorkflows();
    setSelectedWorkflow(selectedWorkflow);
  };

  const handleNewWorkflow = () => {
    setSelectedWorkflow(null);
    setIsCreateMode(true);
  };

  useEffect(() => {
    fetchWorkflows();
  }, []);

  const renderSkeleton = () => {
    return (
      <div className="w-full h-full p-6 flex flex-col gap-12 bg-[#212944]">
        <div className="flex flex-col gap-4">
          <div className=" flex flex-row gap-4 items-center">
            <Skeleton variant="rounded" width={80} height={80} />
            <Skeleton variant="rounded" width={700} height={40} />
          </div>
          <Skeleton variant="rounded" width={800} height={120} />
        </div>
        <div className="flex flex-row gap-4">
          <Skeleton variant="rounded" width={400} height={600} />
          <Skeleton variant="rounded" width={400} height={600} />
          <Skeleton variant="rounded" width={400} height={600} />
        </div>
      </div>
    );
  };

  return (
    <div className="flex flex-row workflows w-screen">
      {showLoader && <FullScreenLoader />}
      <div className={"w-2/12 " + (isEditMode || isCreateMode ? "hidden" : "")}>
        <WorkflowSidebar
          onNewWorkflow={handleNewWorkflow}
          onSelect={handleSelect}
          workflows={workflows}
          selectedWorkflowIndex={workflows.findIndex(
            (workflow: Workflow) => workflow._id === selectedWorkflow?._id
          )}
        />
      </div>
      <div className={isEditMode || isCreateMode ? "w-full" : "w-10/12"}>
        {isCreateMode ? (
          <NewWorkflow
            onWorkflowCreated={(workflow: Workflow) => {
              toast.success("Workflow creado (" + workflow.title + ")");
              setWorkflows([workflow, ...workflows]);
              setSelectedWorkflow(workflow);
            }}
            onClose={() => {
              setIsCreateMode(false);
              setIsEditMode(false);
            }}
          ></NewWorkflow>
        ) : (
          <>
            {selectedWorkflow ? (
              <>
                {isLoading ? (
                  renderSkeleton()
                ) : (
                  <WorkflowPanel
                    isEditMode={isEditMode}
                    onDelete={handleDelete}
                    onDuplicate={handleDuplicate}
                    onSave={handleSave}
                    workflow={selectedWorkflow}
                    onEditModeChange={(mode: boolean) => {
                      setIsEditMode(mode);
                    }}
                  ></WorkflowPanel>
                )}
              </>
            ) : (
              <>
                {isLoading ? (
                  renderSkeleton()
                ) : (
                  <div className="w-full h-full flex justify-center items-center bg-[#212944]">
                    <h1 className="text-2xl text-white">
                      Selecciona un workflow
                    </h1>
                  </div>
                )}
              </>
            )}
          </>
        )}
      </div>
      <ToastContainer
        toastStyle={{
          borderColor: "1px solid white",
          backgroundColor: "#212944",
        }}
        position="top-right"
        autoClose={3000}
        pauseOnFocusLoss
        pauseOnHover
        hideProgressBar
        newestOnTop={false}
        rtl={false}
        draggable
      />
      <OpenModal
        bodyStyle={{ backgroundColor: "#212944" }}
        headerStyle={{ backgroundColor: "#212944" }}
        content={modalContent.content}
        value={isModalOpen.delete ? "delete" : "duplicate"}
        btnValue={modalContent.btnValue}
        fn={modalContent.fn}
      />
    </div>
  );
};

export default Workflows;
