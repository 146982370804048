import axios from "axios";

const getFiles = async (company, queryParams) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_V3}/multimedia/${company}/all${
      queryParams ? `?${queryParams}` : ""
    }`
  );
  return response;
};

const starredFile = async (company, key, starred) => {
  let body = {
    is_starred: starred,
    key,
  };
  const response = await axios.post(
    `${process.env.REACT_APP_API_V3}/multimedia/${company}`,
    body
  );
  return response;
};

const uploadFile = async (file, company) => {
  const formData = new FormData();
  formData.append("file", file);

  try {
    const response = await fetch(
      `${process.env.REACT_APP_API_V3}/multimedia/${company}/upload_file`,
      {
        method: "POST",
        body: formData,
      }
    );

    if (!response.ok) {
      throw new Error("Error al subir el archivo");
    }

    const data = await response.json();
    return data;
  } catch (error) {
    console.error("Error en la solicitud de subida:", error);
    throw error;
  }
};

const deleteFile = async (company, key) => {
  console.log(company, key);
  const response = await axios.delete(
    `${process.env.REACT_APP_API_V3}/multimedia/${company}?key=${key}`
  );
  return response;
};

const getFastAnswers = async (company, chatId) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_V3}/chat_workspace/${company}/fast_answers?chat_id=${chatId}`
  );
  return res.data;
};

const getFastAnswersMessages = async (company, chatId, fastAnswerId) => {
  const res = await axios.get(
    `${process.env.REACT_APP_API_V3}/chat_workspace/${company}/get_fast_answer?chat_id=${chatId}&fast_answer_id=${fastAnswerId}`
  );
  return res.data;
};

export {
  getFiles,
  uploadFile,
  deleteFile,
  getFastAnswers,
  getFastAnswersMessages,
  starredFile,
};
