import { Tooltip } from "@mui/material";
import React, { Dispatch, SetStateAction } from "react";
import { MdModeEdit, MdDelete, MdClose } from "react-icons/md";
import {
  ContentMessageImage,
  ContentMessageText,
  MessageResponse,
} from "./ModalContent";
import classNames from "classnames";

interface MessageItemProps {
  message: MessageResponse;
  setIsEditMessage: Dispatch<SetStateAction<MessageResponse | null>>;
  isEditMessage: MessageResponse | null;
  handleDeleteMessage: (messageToDelete: MessageResponse) => void;
}

const MessageItem = ({
  message,
  setIsEditMessage,
  isEditMessage,
  handleDeleteMessage,
}: MessageItemProps) => {
  const renderTextWithBreaks = (text: string) => {
    return text.split("\n").map((line, index) => (
      <React.Fragment key={index}>
        {line}
        <br />
      </React.Fragment>
    ));
  };

  const renderMessageContent = () => {
    switch (message.type) {
      case "text":
        return (
          <p>
            {renderTextWithBreaks((message.content as ContentMessageText).body)}
          </p>
        );
      case "image":
        return (
          <div>
            <img
              className="max-h-[350px]"
              src={(message.content as ContentMessageImage).url}
              alt="Message image"
            />
            <p className="mt-3">
              {(message.content as ContentMessageImage).caption}
            </p>
          </div>
        );
      case "audio":
        return (
          <div>
            <audio controls>
              <source
                src={(message.content as ContentMessageImage).url}
                type="audio/mpeg"
              />
              Your browser does not support the audio element.
            </audio>
            {(message.content as ContentMessageImage).caption && (
              <p className="mt-3">
                {(message.content as ContentMessageImage).caption}
              </p>
            )}
          </div>
        );
      case "document":
        const documentContent = message.content as ContentMessageImage;
        return (
          <div className="flex flex-col items-center space-y-4">
            <iframe
              src={documentContent.url}
              className="w-full h-64 border border-gray-300 rounded"
              title={`Vista previa de ${documentContent.filename}`}
            ></iframe>
            <div className="flex space-x-4">
              <button
                type="button"
                onClick={() => window.open(documentContent.url, "_blank")}
              >
                Ver Documento
              </button>
              <button
                type="button"
                onClick={() => {
                  const link = document.createElement("a");
                  link.href = documentContent.url;
                  //@ts-ignore
                  link.download = documentContent.filename;
                  document.body.appendChild(link);
                  link.click();
                  document.body.removeChild(link);
                }}
              >
                Descargar
              </button>
            </div>
            <p className="text-sm text-gray-500">{documentContent.filename}</p>
          </div>
        );
      default:
        return <p>Formato de mensaje no soportado.</p>;
    }
  };

  return (
    <div
      className="pl-12 relative group"
      style={{
        zIndex: isEditMessage === message ? "9999" : "1",
      }}
    >
      <div
        className="rounded w-auto max-w-screen-md p-5 text-right relative z-10 cursor-pointer"
        style={{
          backgroundColor: isEditMessage === message ? "#20cab4" : "#1f6466",
        }}
      >
        {renderMessageContent()}
      </div>
      <div className="absolute left-0 top-[50%] flex-col md:flex-row translate-y-[-50%] flex gap-2 -translate-x-[60%] z-[8] transition-all ">
        <button
          type="button"
          className={classNames(
            "p-2 rounded-full flex justify-center items-center transition-all hover:opacity-100",
            isEditMessage === message
              ? "bg-[#ff5758] opacity-100 -mr-6"
              : "opacity-40 bg-[#646464] hover:bg-[#20cab4]"
          )}
        >
          {isEditMessage === message ? (
            <Tooltip title={"Salir del modo edición"} placement="top">
              <div>
                <MdClose
                  className="text-xl text-white"
                  onClick={() => setIsEditMessage(null)}
                />
              </div>
            </Tooltip>
          ) : (
            <MdModeEdit
              className="text-xl text-white"
              onClick={() => setIsEditMessage(message)}
            />
          )}
        </button>
        {isEditMessage !== message && (
          <button
            type="button"
            className="p-2 rounded-full bg-[#646464] hover:bg-[#ff5758] flex justify-center items-center transition-all opacity-40 hover:opacity-100"
          >
            <MdDelete
              className="text-xl text-white"
              onClick={() => handleDeleteMessage(message)}
            />
          </button>
        )}
      </div>
    </div>
  );
};

export { MessageItem };
