import { useContext, useEffect, useState } from "react";

import { useDocumentClick } from "../../hooks/useDocumentClick";
import userContext from "../../utils/userContext";
import MessageInput from "../MessageInput/MessageInput";
import { FileMultimedia } from "../MessageInput/Multimedia/Multimedia";

import { MessageItem } from "./MessageItem";

export interface ContentMessageText {
  body: string;
}
export interface ContentMessageImage {
  url: string;
  caption: string;
  mime_type: string;
  sha256?: string;
  filename?: string;
}

export interface ContentMessageAudio {
  url: string;
  caption: string;
  mime_type: string;
  sha256?: string;
}

export interface MessageResponse {
  type: string;
  content: ContentMessageText | ContentMessageImage | ContentMessageAudio;
}

export interface Response {
  name: string;
  messages: MessageResponse[];
}

interface ModalContentProps {
  setResponsesForm: React.Dispatch<React.SetStateAction<Response>>;
  responsesForm: Response;
  isEditMessage: MessageResponse | null;
  setIsEditMessage: React.Dispatch<
    React.SetStateAction<MessageResponse | null>
  >;
  isFastAnswersInChat?: boolean;
}

const ModalContent = ({
  setResponsesForm,
  responsesForm,
  isEditMessage,
  setIsEditMessage,
  isFastAnswersInChat = false,
}: ModalContentProps) => {
  const { agent } = useContext<any>(userContext);

  const [fastAnswersIsOpen, setFastAnswersIsOpen] = useDocumentClick(
    ".fast-answers-container",
    false
  );
  const [importedFilesSelected, setImportedFilesSelected] = useState<
    FileMultimedia[]
  >([]);
  const [messagesResponses, setMessagesResponses] = useState<MessageResponse[]>(
    responsesForm.messages
  );

  const handleDeleteMessage = (messageToDelete: MessageResponse) => {
    const updatedMessages = messagesResponses.filter(
      (message) => message !== messageToDelete
    );

    setMessagesResponses(updatedMessages);

    setResponsesForm({
      ...responsesForm,
      messages: updatedMessages,
    });
  };

  const handleEditMessage = (text: string) => {
    if (isEditMessage) {
      console.log("isEditMessage: ", isEditMessage);
      const updatedMessages = messagesResponses.map((message) =>
        message === isEditMessage
          ? {
              ...message,
              type:
                importedFilesSelected.length > 0
                  ? importedFilesSelected[0].type
                  : "text",
              content:
                importedFilesSelected.length === 0
                  ? { body: text }
                  : {
                      caption: text,
                      url: importedFilesSelected[0].url,
                      mime_type: importedFilesSelected[0].mime_type,
                    },
            }
          : message
      );

      setMessagesResponses(updatedMessages);

      setResponsesForm({
        ...responsesForm,
        messages: updatedMessages,
      });

      setImportedFilesSelected([]);

      setIsEditMessage(null);
    }
  };

  const handleSendMessage = (text: string, fileRecived: any) => {
    // Avoid send an empty text message
    if (typeof text === "string" && !text.trim() && !(importedFilesSelected.length !== 0 || fileRecived)) {
      return;
    }
    
    let dataMessages = [...messagesResponses];

    if (importedFilesSelected.length !== 0 || fileRecived) {
      let message;
      if (fileRecived) {
        message = {
          type: fileRecived.type,
          content: {
            url: fileRecived.url,
            caption: "",
            mime_type: fileRecived.mime_type,
          },
        };
        dataMessages.push(message as MessageResponse);
      }

      if (importedFilesSelected.length !== 0) {
        importedFilesSelected.forEach((file) => {
          const messageType = file.type;

          const messageContent = {
            url: file.url,
            caption: text,
            mime_type: file.mime_type,
          };

          dataMessages.push({
            type: messageType,
            content: messageContent,
          });
        });
      }
    } else {
      dataMessages.push({
        type: "text",
        content: {
          body: text,
        },
      });
    }
    setImportedFilesSelected([]);
    setMessagesResponses(dataMessages);
    setResponsesForm({
      ...responsesForm,
      messages: dataMessages,
    });
  };

  useEffect(() => {
    setMessagesResponses(responsesForm.messages);
  }, [responsesForm.messages]);

  useEffect(() => {
    // console.log("isEditMessage: ", isEditMessage);
    if (isEditMessage !== null) {
      if (isEditMessage.type !== "text") {
        const fileSelected = {
          //@ts-ignore
          name: isEditMessage.content.filename,
          is_starred: false,
          key: "",
          type: isEditMessage.type,
          //@ts-ignore
          url: isEditMessage.content.url,
          //@ts-ignore
          mime_type: isEditMessage.content.mime_type,
        };
        setImportedFilesSelected([fileSelected]);
      }
    } else {
      setImportedFilesSelected([]);
    }
  }, [isEditMessage]);

  return (
    <>
      {!isFastAnswersInChat && (
        <h3 className="text-xl font-medium text-white text-left p-2">
          Respuesta pre-armada | Response
        </h3>
      )}
      <div className="w-full flex flex-col p-2 gap-5">
        {!isFastAnswersInChat && (
          <div className="w-1/3 flex flex-col">
            <label className="text-sm text-left text-[#a3aeb8] mb-1">
              Nombre*
            </label>
            <input
              className="py-2 w-full px-3 outline-none rounded border border-[#576080] focus:border-[#576080] text-[#09f8e8] bg-[#1a2238]"
              id="name"
              name="name"
              type="text"
              required
              onChange={(e) =>
                setResponsesForm({ ...responsesForm, name: e.target.value })
              }
              value={responsesForm.name}
            />
          </div>
        )}
        <div className="w-full flex flex-col gap-3">
          {!isFastAnswersInChat && (
            <>
              <label className="text-sm text-left text-[#a3aeb8] mb-1">
                Mensajes*
              </label>
              <span className="text-sm text-[#7b838b] text-left">
                Crea los mensajes que serán enviados a un usuario al utilizar la
                respuesta pre-armada y utiliza parámetros para personalizarlos.
                Debes crear al menos un mensaje.
              </span>
            </>
          )}
          <div className="w-full relative h-[50vh] min-h-[500px] flex flex-col rounded border border-[#576080] overflow-hidden bg-[#1a2238]">
            <div
              className="h-full flex flex-col px-6 py-4 items-end gap-4 justify-start"
              style={{
                // overflowY: isEditMessage !== null ? "hidden" : "auto",
                overflowY: "auto",
              }}
            >
              {isEditMessage && (
                <div className="bg-[#0000003b] z-[99] absolute top-0 left-0 w-full h-full backdrop-blur"></div>
              )}
              {messagesResponses.map((message, i) => (
                <MessageItem
                  key={`key-message-item-${i}`}
                  message={message}
                  setIsEditMessage={setIsEditMessage}
                  isEditMessage={isEditMessage}
                  handleDeleteMessage={handleDeleteMessage}
                />
              ))}
            </div>
            <MessageInput
              onSendMessage={
                isEditMessage !== null ? handleEditMessage : handleSendMessage
              }
              refetchMessages={() => console.log("asdasda")}
              setFastAnswersIsOpen={setFastAnswersIsOpen}
              fastAnswersIsOpen={fastAnswersIsOpen}
              importedFilesSelected={importedFilesSelected}
              showFastAnswers={false}
              setImportedFilesSelected={setImportedFilesSelected}
              defaultMessage={
                isEditMessage !== null
                  ? isEditMessage.type === "text"
                    ? (isEditMessage.content as ContentMessageText).body
                    : (
                        isEditMessage.content as
                          | ContentMessageImage
                          | ContentMessageAudio
                      ).caption
                  : ""
              }
            />
          </div>
        </div>
      </div>
    </>
  );
};

export { ModalContent };
