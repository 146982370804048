import { FormControlLabel, Menu, TextareaAutosize, TextField, Tooltip } from "@mui/material";
import React, { useContext, useEffect, useState } from "react";
import Select, { SelectChangeEvent } from '@mui/material/Select';
import userContext from "../../utils/userContext";
import Checkbox from '@mui/material/Checkbox';
import SearchAutocomplete from "../SearchAutocomplete/SearchAutocomplete";
import { toast } from "react-toastify";
import { addPlantillaSingle, addPlantillaMultiple, getAgents, getAreas, getPlantillasForm, getCsvExample } from "../../services/Plantillas.config";
import { TiInfoLarge } from "react-icons/ti";


type FormPlantillas = {
	agent_email: string,
	name: string,
	type: string,
	area: string,
	description: string,
	tags: any[],
	products: any[],
	flow: any[],
	ad_id: string,
	trackeable: boolean,
	category: string,
	categorySelect: string,
	trigger: string,
	source_checker: string
}

type Props = {
	closeModal: (value: React.SetStateAction<boolean>) => void
	// setTableReload: () => Promise<void>
}
type RepeatType = {
	count: number,
	delay_sec: number,
	till_done: boolean
  }
interface WorkflowType {
	actions: string[]
	companyId: string,
	description: string,
	repeat: RepeatType,
	title: string,
	version: number,
	_id: string
  }
interface ProductType {
	id: string, 
	name: string
}
interface PlantillaType {
	id: string
	name: string
	text: string
}

const AddTemplateFromChat: React.FC<Props> = ({
    closeModal,
		// setTableReload
}) => {	
	const [form, setForm] = useState<any>(
		{
			area: 'WITH AGENT',
			assign_to_agent: '',
			phone_number: '',
			new_contact_name: '',
			template_name: "",
			forced_send: false,
			parameters: [],
			tags: [],
			products: [],
			flow: [],
			description: "",
			campaign_name : ""
		}
	)

	const [plantillas, setPlantillas] = useState<PlantillaType[]>([])
	const { agent, clientUser } = useContext(userContext) as any;

	const [selectedPlantillas, setSelectedPlantillas] = useState<PlantillaType[]>([])
	
	const handleChange = (e:React.ChangeEvent<HTMLInputElement  | HTMLTextAreaElement | SelectChangeEvent>) => {
		//@ts-ignore
		setForm({...form, [e.target.name]: e.target.name === 'forced_send' ? e.target.checked : e.target.value})
	}

	const addPlantilla = async(body:any, single:boolean) => {
		const process = toast.loading("Agregando Plantilla...")
		let response
		try {

      response = await addPlantillaSingle(agent.company, body)

			toast.update(process, 
				{
					render: response.message, 
					type: "success", 
					isLoading: false, 
					autoClose: 2000,
				}
			);
			setTimeout(() => {
				// setTableReload()
				closeModal(false)
			}, 1500)
		}
		catch (e) {
			console.log('e: ', e)
			toast.update(process, 
				{
					//@ts-ignore
					render: e.response.data.message, 
					type: "error", 
					isLoading: false, 
					autoClose: 800,
				}
			);
		}
	}

	const sendForm = async () => {

		let formToSend = form

		formToSend.template_name = selectedPlantillas[0].name
    formToSend.phone_number = clientUser.phone_number
    formToSend.agent_email = agent.email
    formToSend.assign_to_agent = agent.email

    delete formToSend.campaign_name

    addPlantilla(formToSend, true)
	}


	const getAllPlantillas = async () => {
		try {
			const data = await getPlantillasForm(agent.company)
			console.log('data: ', data)
			setPlantillas(data)
		} catch(e) {
			console.log('Error fetching plantillas', e)
		}
	}

	
	useEffect(() => {
		getAllPlantillas()
		setForm({...form, agent_email : agent.email})
	}, [])


	const onPlantillasSelect = (plantillaItem:any) => {
		//@ts-ignore
		if (!selectedPlantillas.some((p: any) => p.id === plantillaItem.id)) {
			// @ts-ignore
			setSelectedPlantillas([plantillaItem])
		} else {
			toast.error('La plantilla ya está en la lista.', {
				autoClose: 2000,
			})
		}
	}



	return(
		<div className="flex gap-5 border-t border-[#1a2238]">
			<div className="py-5 w-full pr-5">
        <>
          <div className="flex flex-col">
            <h2 className="text-left text-white mb-5 mt-5 ">Plantillas disponibles</h2>
            <div className="overwriteSearchStyles relative flex justify-between">
              <SearchAutocomplete 
                placeholder='Buscar plantillas' 
                data={plantillas} 		
                autoOpen={false}
				sx={{width: '100%'}}
                onSelect={onPlantillasSelect}
              />
              {selectedPlantillas.length > 0 && 
                <button className="left-1" onClick={() => setSelectedPlantillas([])}>
                  Borrar seleccion
                </button>
              }
            </div>
            <div className="w-full mb-3">
              {selectedPlantillas.map( (plantilla, i) => 
									<div key={i} className="relative flex-col flex items-start justify-start mt-3 float-left rounded px-2 pr-7 py-1 bg-[#1F6466]">
										<span className="text-[#aaa] text-xs text-left">{plantilla.name}</span>
										<p className="text-white text-sm text-left">{plantilla.text}</p>
									</div>
              )}
            </div>
            <div className="flex gap-2 items-center justify-start">
              
							<FormControlLabel control={<Checkbox 
                checked={form.forced_send}
                name='forced_send'
                id='forced_send'
                inputProps={{ 'aria-label': 'controlled' }}
                onChange={(e) => handleChange(e)}
                defaultChecked 
              />} label="Envio forzado" />
							<Tooltip title={'Las plantillas no serán enviadas si: La misma plantilla fue enviada al cliente previamente.El cliente se encuentra actualmente siendo siendo atendido por otro agente.El envío forzado hará que la plantilla se envíe de todas formas.'} placement="right">
									<div>
										<TiInfoLarge className="text-[24px] text-[#acb8c0]" />
									</div>
							</Tooltip>
            </div>
            
            <div className="flex justify-end w-full mt-5 gap-5">
              <button className="bg-[#ff5758] rounded text-white px-6 py-2 text-sm" onClick={() => closeModal(false)}>Descartar</button>
              <button className="bg-[#20cab4] rounded text-white px-6 py-2 text-sm" onClick={() => sendForm()}>Enviar ahora</button>
            </div>
          </div>

        </>
			</div>
		</div>
	)
}

export default AddTemplateFromChat