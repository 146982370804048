import React, { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import userContext from "../utils/userContext";
import OpenNotification from "../components/OpenNotification";
import logo1 from "../images/Chatty_Logo01.png";
import logo3 from "../images/GualdAI_Logo03.png";
import { Spinner } from "flowbite-react";
import { useEffect } from "react";
import { useAuth0 } from '@auth0/auth0-react'
import { sendNumberRegistration } from "../services/Register.service";
import { toast, ToastContainer } from "react-toastify";
const Login: React.FC = () => {

  const { setIsAlert, refetch } = useContext<any>(userContext);
  const { loginWithRedirect, isAuthenticated, isLoading } = useAuth0();
  interface login {
    email: string;
    password: string;
  }
  const [loginInfo, setLoginInfo] = useState<login>({
    email: "",
    password: "",
  });
  const handleLogin = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/home",
      },
    });
  };
  const handleRegister = async () => {
    await loginWithRedirect({
      appState: {
        returnTo: "/register",
      },
    });
  }

  const [isSmallScreen, setIsSmallScreen] = useState(false);
  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth < 768);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="min-h-screen w-screen flex font-sans items-center justify-center text-black bg-white">
      <ToastContainer 
        toastStyle={{ 
          border: '1px solid white',
          backgroundColor: "#212944" 
        }}
        />
      <OpenNotification />
      <div className="md:w-[75rem] w-full md:h-[35rem] h-screen flex flex-col md:flex-row justify-between rounded-lg drop-shadow-lg shadow-lg">
        <div
          className={`md:w-5/12 w-full md:h-full h-46 md:flex items-center justify-center bg-[#1B2238] md:rounded-l-lg md:rounded-tr-none flex-col ${
            isSmallScreen ? "p-14" : ""
          }`}
        >
          <div className="flex items-center justify-center md:mt-0 mt-4">
            <img
              src={logo1}
              alt="Logo GualdAI"
              className={`md:h-32 ${isSmallScreen ? "h-32" : "h-auto"}`}
            />
          </div>
          <div className="flex items-center justify-center my-2">
            <p className="md:block text-lg text-white text-center font-semibold md:text-2xl">
              Chatty
            </p>
          </div>
          <div className="flex justify-center items-center my-2">
            <p className="text-white text-base font-medium mr-2">
              A product by
            </p>
            <img className="h-4" src={logo3} alt="Logo GualdAI" />
          </div>
        </div>
        <div
          className={`h-full md:w-[59.33%] w-full flex items-center justify-center bg-[white] md:rounded-r-lg md:rounded-bl-none ${
            isSmallScreen ? "mt-[-10px]" : ""
          }`}
        >
          <div
            className={`w-11/12 md:w-5/6 h-5/6 flex flex-col justify-around items-center ${
              isSmallScreen ? "mt-[-10px]" : ""
            }`}
          >
            <div
              className={`w-full flex justify-center`}
            >
              <div className="w-full md:w-3/5 h-full flex flex-col justify-around">
                <div className="w-full flex flex-col items-center">
                  <p className="text-[#1B2238] font-semibold md:text-base text-sm mt-10 mb-6">
                    Iniciar Sesión
                  </p>
                </div>
              </div>
            </div>

            <div className="rounded-md border text-[#1B2238] border-[#283253] px-3 py-2 hover:bg-[#0fb6a1] hover:border-[#0fb6a1] hover:text-white trainsition-all duration-300">
              {isLoading ? (
                <Spinner hidden={isLoading ? false : true} />
                ) : (
                  <button className="px-2 cursor-pointer" onClick={()=> handleLogin()}>Acceder</button>
                )}
            </div>

            <div className="rounded-md text-[#1B2238] px-3 py-2 trainsition-all duration-300">
              {isLoading ? (
                <Spinner hidden={isLoading ? false : true} />
                ) : (
                  <button className="px-2 cursor-pointer" onClick={() => handleRegister()}>Registro</button>
                )}
            </div>
            </div>
          </div>
      </div>
    </div>
  );
};
export default Login;