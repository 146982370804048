import React from "react";
import { FaBolt } from "react-icons/fa";
import { ModalFastAnswers } from "./FastAnswers/ModalFastAnswers";

interface Props {
  updateInput: React.Dispatch<React.SetStateAction<string>>;
  setFastAnswersIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
  fastAnswersIsOpen: boolean;
  handleSendMessage: () => void;
  refetchMessages: () => void;
}

export type FastChat = {
  ID: number;
  name: string;
  body1: string;
  content: string;
};

export const FastAnswers: React.FC<Props> = ({
  setFastAnswersIsOpen,
  fastAnswersIsOpen,
  refetchMessages,
}) => {

  return (
    <>
      <button
        onClick={() => setFastAnswersIsOpen(true)}
        type="button"
        className="pb-2"
      >
        <FaBolt className="text-white text-2xl" />
      </button>
      <ModalFastAnswers
        isOpen={fastAnswersIsOpen}
        onClose={() => setFastAnswersIsOpen(false)}
        refetchMessages={() => refetchMessages()}
      />
    </>
  );
};

export default FastAnswers;
