import React, { useEffect } from "react";
import { PaginationProps } from "../interfaces/paginationProps.interface";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import { Conversations } from "./Conversations/Conversations";
import ConversationEmpty from "./ConversationEmpty";

export const Pagination: React.FC<PaginationProps> = ({
  currentPage,
  setCurrentPage,
  totalChats,
  setSkip,
  isLoading,
  isPreviousData,
  setInboxToggle,
  setUser,
  skeletonRef,
  refetchMyConversations,
  conversations,
  checkConversations,
  searchRef,
  unreadConversations,
  refetch,
  filter,
  isSelectChatOpen,
  setFilterSelection,
  previewFullData
}) => {

  useEffect(() => {
    setCurrentPage(currentPage > totalChats ? 1 : currentPage);
  }, [totalChats]);

  useEffect(() => {
    setSkip(currentPage <= 1 ? 0 : currentPage);
  }, [currentPage]);

  return (
    <>
      {conversations?.length ? (
        <Conversations
          setFilterSelection={setFilterSelection}
          conversations={conversations}
          isLoading={isLoading}
          isPreviousData={isPreviousData}
          setInboxToggle={setInboxToggle}
          setUser={setUser}
          skeletonRef={skeletonRef}
          checkConversations={checkConversations}
          refetchMyConversations={refetchMyConversations}
          searchRef={searchRef}
          refetch={refetch}
          isSelectChatOpen={isSelectChatOpen}
          filter={filter}
        />
      ) : (
        <ConversationEmpty prop="admin" />
      )}
      {Object.keys(previewFullData?.broader_previews ?? {}).length !== 0 && (
          <div className="max-h-[60%] pt-6 overflow-y-auto">
            <h4 className="text-white text-lg text-left mb-2 px-3">Otras coincidencias y su ubicación</h4>
            {Object.keys(previewFullData?.broader_previews ?? {}).map((title, index) => (
              <>
                <p key={index} className="text-[#acb8c0] text-lg text-left mb-3 px-3">{title}</p>
                {/* @ts-ignore */}
                {previewFullData?.broader_previews[title].length && (
                   <Conversations
                      setFilterSelection={setFilterSelection}
                      conversations={previewFullData?.broader_previews[title]}
                      isLoading={isLoading}
                      isPreviousData={isPreviousData}
                      setInboxToggle={setInboxToggle}
                      setUser={setUser}
                      skeletonRef={skeletonRef}
                      checkConversations={checkConversations}
                      refetchMyConversations={refetchMyConversations}
                      searchRef={searchRef}
                      refetch={refetch}
                      isSelectChatOpen={isSelectChatOpen}
                      filter={filter}
                      isBroaderPreview={true}
                    />
                )}
              </>
            ))}
          </div>
        )}
      <div>

      </div>
      <div className="flex flex-col items-center mb-2"  style={{paddingBottom : 'env(safe-area-inset-bottom, 0px)'}}>
        <span className="text-sm text-white dark:text-gray-700">
          Chats{" "}
          <span className="font-semibold text-white dark:text-gray-900">

            {/* REVISAR ESTA LOGICA */}
            {currentPage === 1
              ? `1-${currentPage + 9 <= totalChats ? currentPage + 9 : totalChats}`
              : 
              currentPage === 2 ? `${(currentPage * 5)  + 1}-${currentPage * 10 <= totalChats ? currentPage * 10 : totalChats}`
              : `${((currentPage -1) * 10)  + 1}-${currentPage * 10 <= totalChats ? currentPage * 10 : totalChats}`}
          </span>{" "}
          de{" "}
          <span className="font-semibold text-white dark:text-gray-900">
            {totalChats ? totalChats : "?"}
          </span>{" "}
        </span>
        <div className="inline-flex mt-2 xs:mt-0">
          <button
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-principalColor rounded-l hover:bg-principalColor hover:text-white transition duration-300 ease-in-out transform hover:scale-110"
            onClick={() => {
              setCurrentPage(currentPage <= 1 ? 1 : currentPage - 1);
            }}
          >
            <BsArrowLeft className="text-xl font-bold" />
          </button>
          <button
            className="inline-flex items-center px-4 py-2 text-sm font-medium text-principalColor rounded-r hover:bg-principalColor hover:text-white transition duration-200 ease-in-out transform hover:scale-110"
            onClick={() => { currentPage * 10 <= totalChats ? setCurrentPage(currentPage + 1) : console.log('No pagino')}}
          >
            <BsArrowRight className="text-xl font-bold" />
          </button>
        </div>
      </div>
    </>
  );
};
