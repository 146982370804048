import axios from "axios";

const getResponses = async (company) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_V3}.1/company_assets/${company}/fast_answers`
  );
  return response;
};

const deleteResponse = async (company, id) => {
  const response = await axios.delete(
    `${process.env.REACT_APP_API_V3}.1/company_assets/${company}/fast_answers/${id}`
  );
  return response;
};

const getResponse = async (company, id) => {
  const response = await axios.get(
    `${process.env.REACT_APP_API_V3}.1/company_assets/${company}/fast_answers/${id}`
  );
  return response;
};

const postResponses = async (data, company, action, id) => {
  let URL = `${process.env.REACT_APP_API_V3}.1/company_assets/${company}/fast_answers`;
  if (action === "edit") {
    URL = `${process.env.REACT_APP_API_V3}.1/company_assets/${company}/fast_answers/${id}`;
  }
  try {
    const response = await fetch(URL, {
      method: action === "edit" ? "PUT" : "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    });

    if (!response.ok) {
      throw new Error("Error al subir responses");
    }

    const responseData = await response.json();
    return responseData;
  } catch (error) {
    console.error("Error en la solicitud de subida:", error);
    throw error;
  }
};

export { getResponses, postResponses, getResponse, deleteResponse };
