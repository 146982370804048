import React, { useState, useEffect, useRef, useCallback } from 'react'

// Use OGV module to alllow play ogg files in Safary
import * as ogv from "ogv/dist/ogv";
import AudioUtil, { usePlaybackRate } from '../utils/Audio';

// Set OCV assets path
ogv.OGVLoader.base = '/assets/ogv';

/**
 * https://github.com/bvibber/ogv.js/issues/600
 * https://github.com/swevans/unmute/blob/master/unmute.js#L171
 * 
 * A very short bit of silence to be played with <audio>, which forces AudioContext onto the ringer channel.
 * NOTE: The silence MP3 must be high quality, when web audio sounds are played in parallel the web audio sound is mixed to match the bitrate of the html sound.
 * This file is 0.01 seconds of silence VBR220-260 Joint Stereo 859B
 * The str below is a "compressed" version using poor mans huffman encoding, saves about 0.5kb
 **/
function huffman(count: number, repeatStr: string) {
  let e = repeatStr;
  for (; count > 1; count--)
    e += repeatStr; return e;
}

const silence = "data:audio/mpeg;base64,//uQx" + huffman(23, "A") + "WGluZwAAAA8AAAACAAACcQCA" + huffman(16, "gICA") + huffman(66, "/") + "8AAABhTEFNRTMuMTAwA8MAAAAAAAAAABQgJAUHQQAB9AAAAnGMHkkI" + huffman(320, "A") + "//sQxAADgnABGiAAQBCqgCRMAAgEAH" + huffman(15, "/") + "7+n/9FTuQsQH//////2NG0jWUGlio5gLQTOtIoeR2WX////X4s9Atb/JRVCbBUpeRUq" + huffman(18, "/") + "9RUi0f2jn/+xDECgPCjAEQAABN4AAANIAAAAQVTEFNRTMuMTAw" + huffman(97, "V") + "Q==";

export interface Props {
  audioUrl: string;
}

// Detect if the browser is Safary to use ogv.js
const isSafari = (navigator.userAgent.indexOf("Safari") !== -1 && navigator.userAgent.indexOf("Chrome") === -1);

const AudioPlayer: React.FC<Props> = ({ audioUrl }) => {
  const [isPlaying, setIsPlaying] = useState(false)
  const [loaded, setLoaded] = useState(false)
  const [duration, setDuration] = useState(0)
  const [currentTime, setCurrentTime] = useState(0)
  const audioRef = useRef<HTMLAudioElement | null>(null)
  const progressRef = useRef<HTMLDivElement | null>(null)
  const [player, setPlayer] = useState<HTMLAudioElement>();
  const playbackRate = usePlaybackRate();

  // Add an unmute element to bypass issue https://github.com/bvibber/ogv.js/issues/600
  const [unmute, setUnmute] = useState<HTMLAudioElement>();

  const setPlay = useCallback(() => {
    setIsPlaying(true);

    unmute && unmute.play();

    function updateTime() {
      if (!player) {
        return;
      }

      setIsPlaying(isPlaying => {
        if (isPlaying) {
          setCurrentTime(player.currentTime);
          setTimeout(updateTime, 50);
        }

        return isPlaying;
      });
    }

    updateTime();
  }, [player, unmute]);

  useEffect(() => {
    if (isSafari) {
      const newPlayer = new ogv.OGVPlayer();
      newPlayer.src = audioUrl;

      setPlayer(newPlayer);
    }
  }, [audioUrl]);

  useEffect(() => {
    audioRef?.current && setPlayer(audioRef?.current);
  }, [audioRef]);

  // Set playbackrate
  useEffect(() => {
    player && (player.playbackRate = playbackRate)
  }, [player, playbackRate]);

  const setPause = useCallback(() => {
    setIsPlaying(false);
    unmute && unmute.pause();
  }, [unmute]);

  const setEnded = useCallback(() => {
    setIsPlaying(false);
    setCurrentTime(0);
    unmute && unmute.pause();
  }, [unmute]);

  useEffect(() => {
    if (!player) {
      return;
    }

    const setAudioData = () => {
      setDuration(player.duration);
      setCurrentTime(player.currentTime);

      // Set the element to unmute iPhone
      if (isSafari) {
        const el = document.createElement('audio');

        el.loop = true;
        el.src = silence;
        setUnmute(el);
      }

      setLoaded(true);
    }

    // Add event listeners
    player.addEventListener('loadeddata', setAudioData)
    player.addEventListener('loadedmetadata', setAudioData)
    player.addEventListener('pause', setPause)
    player.addEventListener('play', setPlay)
    player.addEventListener('ended', setEnded)

    // Remove event listeners on cleanup
    return () => {
      player.removeEventListener('loadeddata', setAudioData)
      player.removeEventListener('loadedmetadata', setAudioData)
      player.removeEventListener('pause', setPause)
      player.removeEventListener('play', setPlay)
      player.removeEventListener('ended', setEnded)
    }
  }, [player, setPlay, setEnded, setPause]);

  const togglePlayPause = () => {
    if (!player) {
      return;
    }

    if (isPlaying) {
      player.pause()
    } else {
      player.play()
    }

    setIsPlaying(!isPlaying)
  }

  const formatTime = (time: number) => {
    const minutes = Math.max(0, Math.floor(time / 60))
    const seconds = Math.max(0, Math.floor(time % 60))
    return `${minutes}:${seconds.toString().padStart(2, '0')}`
  }

  const handleProgressChange = (event: React.MouseEvent<HTMLDivElement>) => {
    const progress = progressRef.current
    if (!player || !progress) return

    const rect = progress.getBoundingClientRect()
    const percent = (event.clientX - rect.left) / rect.width

    player.currentTime = percent * player.duration;
    setCurrentTime(player.currentTime);
  }

  return (
    <div className="max-w-full w-[300px] bg-[#1f6466] p-4 rounded-lg z-0 mt-1" key={audioUrl}>
      {!isSafari && <audio ref={audioRef} src={audioUrl} />}

      <div className="flex items-center space-x-4">
        <button
          onClick={() => loaded && togglePlayPause()}
          className={"w-10 h-10 flex items-center justify-center bg-green-500 rounded-full text-white focus:outline-none " + (!loaded ? 'cursor-default' : '')}
        >
          {!loaded ?
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><circle cx="12" cy="12" r="1" /><circle cx="19" cy="12" r="1" /><circle cx="5" cy="12" r="1" /></svg>
            : (isPlaying ? <>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><rect x="14" y="4" width="4" height="16" rx="1" /><rect x="6" y="4" width="4" height="16" rx="1" /></svg>
            </> : <>
              <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polygon points="6 3 20 12 6 21 6 3" /></svg>
            </>)}
        </button>
        <div className="flex-grow">
          {/* Progress bar */}
          <div
            ref={progressRef}
            onClick={(e) => loaded && handleProgressChange(e)}
            className={"h-2 bg-gray-300 rounded-full overflow-hidden " + (loaded ? 'cursor-pointer' : '')}
          >
            <div
              className="h-full bg-green-500 rounded-full"
              style={{ width: `${(currentTime / duration) * 100}%` }}
            ></div>
          </div>
          <div className="flex justify-between text-xs text-gray-500 mt-2 leading-3">
            {/* Current play time */}
            <span className='inline-block pt-[5px]'>
              {formatTime(currentTime)}
            </span>
            <span>
              {/* Audio duration time */}
              {formatTime(duration)}

              {/* Download button */}
              <a href={audioUrl} target="_blank" rel="noreferrer" className='inline-block align-middle ml-3' download>
                <svg className="w-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2.5" stroke-linecap="round" stroke-linejoin="round"><path d="M12 13v8l-4-4"/><path d="m12 21 4-4"/><path d="M4.393 15.269A7 7 0 1 1 15.71 8h1.79a4.5 4.5 0 0 1 2.436 8.284"/></svg>
              </a>

              {/* Playback rate button */}
              <span
                className="bg-green-500 rounded-full text-white cursor-pointer ml-3 w-10 inline-block text-center py-1"
                onClick={AudioUtil.changePlaybackRate.bind(AudioUtil)}
              >
                {playbackRate.toString()}x
              </span>
            </span>
          </div>
        </div>
      </div>
    </div>
  )
}

export default AudioPlayer;
