import React, { useContext, useEffect, useState } from "react";
import { TextareaAutosize, TextField, Box } from "@mui/material";
import { toast, ToastContainer } from "react-toastify";
import { Button, Modal } from "flowbite-react";
import userContext from "../../utils/userContext";
import { FiPlus, FiTrash } from "react-icons/fi";
import {
  createTopic,
  getMessagesFromTopic,
  updateTopic,
} from "../../services/Topics.service";

type Props = {
  selectedRow: any;
  tableGetData: () => Promise<void>;
  closeModal: () => void;
};

const TopicConfig = ({ closeModal, tableGetData, selectedRow }: Props) => {
  const { agent } = useContext<any>(userContext);
  const [form, setForm] = useState({
    agent_email: agent.email,
    name: "",
    messages: [
      {
        placeholder:
          "Ejemplo: Hola Chatty! Me gustaría obtener información sobre sus servicios de mensajería 💬",
        content: "",
        is_default: false,
      },
      {
        placeholder:
          "Ejemplo: Hola! 👋 Deseo obtener información acerca de Chatty.",
        content: "",
        is_default: false,
      },
      {
        placeholder:
          "Ejemplo: Hola equipo de Chatty! Escribo para pedirles información de mensajería para empresas, por favor 🙏",
        content: "",
        is_default: false,
      },
      {
        placeholder:
          "Ejemplo: Hola 👋 Me comunico para obtener información sobre Chatty!",
        content: "",
        is_default: false,
      },
      {
        placeholder:
          "Ejemplo: Hola! Escribo para recibir información sobre Chatty 💬",
        content: "",
        is_default: false,
      },
    ],
  });

  const getMessages = async (selectedRow: any) => {
    try {
      const data = await getMessagesFromTopic(agent.company, selectedRow.id);
      setForm({
        ...form,
        name: selectedRow.name,
        messages: data.data.messages,
      });
    } catch {}
  };

  useEffect(() => {
    if (selectedRow) {
      console.log("Selected row: ", selectedRow);
      getMessages(selectedRow);
    }
  }, [selectedRow]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;
    setForm({
      ...form,
      [name]: value,
    });
  };

  const handleMessageChange = (
    e: React.ChangeEvent<HTMLTextAreaElement>,
    index: number
  ) => {
    const newMessages = [...form.messages];
    newMessages[index].content = e.target.value;
    setForm({ ...form, messages: newMessages });
  };

  const postTopic = async () => {
    const process = toast.loading("Agregando Source...");
    const formToSend = form.messages.map((message) => {
      return { content: message.content, is_default: message.is_default };
    });
    try {
      if (selectedRow) {
        const res = await updateTopic(
          agent.company,
          formToSend,
          selectedRow.id
        );
      } else {
        const res = await createTopic(agent.company, formToSend);
      }
      toast.update(process, {
        //@ts-ignore
        render: res.message,
        type: "success",
        isLoading: false,
        autoClose: 1000,
      });
      setTimeout(() => {
        closeModal();
        tableGetData();
      }, 1000);
    } catch (e) {
      toast.update(process, {
        //@ts-ignore
        render: e.response.data.error,
        type: "error",
        isLoading: false,
        autoClose: 1000,
      });
    }
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();
    postTopic();
  };

  const handleAddMessage = () => {
    setForm({
      ...form,
      messages: [
        ...form.messages,
        { content: "", is_default: false, placeholder: "" },
      ],
    });
  };

  const handleDeleteMessage = (index: number) => {
    const newMessages = form.messages.filter((_, i) => i !== index);
    setForm({ ...form, messages: newMessages });
  };

  return (
    <Box
      component="form"
      sx={{
        "& .MuiTextField-root": { m: 1, width: "100%" },
      }}
      noValidate
      autoComplete="off"
      onSubmit={handleSubmit}
      className="form-config-container"
    >
      <div className="flex flex-col relative">
        <h2 className="text-left text-white mb-5">Agregar Topic</h2>
        <p className="text-sm text-[#a3aeb8] my-3 text-left">
          Crea mensajes distintos pero intercambiables entre sí,
          <br />
          El mínimo recomendado son entre 5 y 10 mensajes, aunque mientras más,
          mejor funcionará.
          <br />
          Tip: utiliza íconos distintos en tus mensajes.
        </p>
        <div className="flex flex-col relative mb-3">
          <label className="text-sm text-[#a3aeb8] mb-3 text-left">
            Nombre
          </label>
          <TextField
            id="name"
            name="name"
            required
            value={form.name}
            onChange={handleChange}
            variant="outlined"
            fullWidth
            className="mb-5 mx-0 !ml-0 input-source"
          />
        </div>
        {form.messages.map((message, i) => (
          <div key={i} className="flex flex-col relative mb-3">
            <div className="flex justify-between items-center">
              <label className="text-sm text-[#a3aeb8] mb-3 text-left">
                Mensaje {i + 1}
              </label>
              <button
                className=""
                type="button"
                onClick={() => handleDeleteMessage(i)}
              >
                {form.messages.length > 5 && (
                  <FiTrash className="text-gray-400" />
                )}
              </button>
            </div>
            <TextareaAutosize
              id={`message-${i}`}
              required
              name={`message-${i}`}
              onChange={(e) => handleMessageChange(e, i)}
              value={message.content}
              placeholder={message?.placeholder ?? ""}
              minRows={3}
              className="mb-3 outline-none bg-[#1a2238] text-[14px] text-[#43f8e8] rounded"
            />
          </div>
        ))}
        <div className="flex items-center w-full justify-end">
          <p className="text-sm text-[#a3aeb8] mr-3">Agregar mensaje</p>
          <Button onClick={handleAddMessage} className="bg-principalColor">
            <FiPlus className="text-white" />
          </Button>
        </div>
      </div>
      <Modal.Footer
        className="flex justify-center"
        style={{ paddingBottom: 0 }}
      >
        <Button className="bg-principalColor" type="submit">
          Confirmar
        </Button>
        <Button color="failure" onClick={() => closeModal()}>
          Cancelar
        </Button>
      </Modal.Footer>
      <ToastContainer
        toastStyle={{
          border: "1px solid white",
          backgroundColor: "#212944",
        }}
      />
    </Box>
  );
};

export default TopicConfig;
