import { useState, useEffect } from 'react';

import StateStore from '../libraries/StateStore';

class AudioUtil extends StateStore {
	public storageKey: string = 'com.letschatty.app';

	constructor () {
		super();

		// Audio playback rate
		this.setStateProperty('playbackRate', Number(localStorage.getItem(`${this.storageKey}:audio_playback_rate`) || 1));

		// Store playback
		this.on('playbackRate', (nextPlaybackRate: number) => {
			localStorage.setItem(`${this.storageKey}:audio_playback_rate`, String(nextPlaybackRate));
		});
	}

	/**
	 * Change playback rate
	 */
	public changePlaybackRate() {
		const playbackRate = audioUtil.getStateProperty('playbackRate') as number;

		this.setStateProperty('playbackRate', playbackRate >= 2.5 ? 1 : playbackRate + 0.5);
	}
}

const audioUtil = new AudioUtil();

export function usePlaybackRate () {
	const [playbackRate, setPlaybackRate] = useState(audioUtil.getStateProperty('playbackRate') as number);

	useEffect(() => {
		const id = audioUtil.on('playbackRate', setPlaybackRate);

		return () => {
			audioUtil.removeListener('playbackRate', id);
		};
	}, [playbackRate]);

	return playbackRate;
}


export default audioUtil;
