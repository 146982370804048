import React, { useEffect, useState, useRef, useContext } from "react";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import { Button, Modal } from "flowbite-react";
import MultiSelect from "./MultiSelect";
import userContext from "../../utils/userContext";
import "./Form.css";
import { actionTable } from "../../services/Tables.service";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  MessageResponse,
  ModalContent,
  Response,
} from "../Responses/ModalContent";
import { getResponse, postResponses } from "../../services/responses.service";

const RESPONSES_MODEL = {
  name: "",
  messages: [],
};

const TAGS_MODEL = {
  name: "",
  color: "",
  icon_name: "",
  description: "",
  category: "",
  is_visible: "",
  created_at: new Date(),
};

const PURE_ADS_MODEL = {
  ad_id: "",
  name: "",
  description: "",
  tags_id_and_name: [],
};
const OTHER_SOURCERS_MODEL = {
  name: "",
  description: "",
  tags_id_and_name: [],
};
const TRIGGERS_MODEL = {
  posibilidad: "",
  corte: "",
  other_source_id_and_name: [],
  pure_ad_id_and_name: [],
};
const IMPURE_ADS_MODEL = {
  url: "",
  pure_ad_id_and_name: [],
};

type valueData = {
  id: string;
  name: string;
};

interface TriggersType {
  posibilidad: string;
  corte: string;
  other_source_id_and_name: valueData[];
  pure_ad_id_and_name: valueData[];
}

type Props = {
  tableSelected: string;
  action: string;
  selectedRow: any;
  tableGetData: () => Promise<void>;
  closeModal: (isEditMessage: MessageResponse | null) => void;
  directClose: () => void;
};

const FormConfig: React.FC<Props> = ({
  tableSelected,
  action,
  selectedRow,
  tableGetData,
  closeModal,
  directClose,
}) => {
  const { agent } = useContext<any>(userContext);

  const [isEditMessage, setIsEditMessage] = useState<MessageResponse | null>(
    null
  );
  const [modalConfirmation, setModalConfirmation] = useState(false);

  const [formType, setFormType] = useState(tableSelected);
  const [responsesForm, setResponsesForm] = useState<Response>(RESPONSES_MODEL);
  const [initialResponsesForm, setInitialResponsesForm] = useState<
    MessageResponse[]
  >([]);
  const [tagsForm, setTagsForm] = useState(TAGS_MODEL);
  const [pureAdsForm, setPureAdsForm] = useState(PURE_ADS_MODEL);
  const [impureAdsForm, setImpureAdsForm] = useState(IMPURE_ADS_MODEL);
  const [otherSourcersForm, setOtherSourcersForm] =
    useState(OTHER_SOURCERS_MODEL);
  const [triggersForm, setTriggersForm] =
    useState<TriggersType>(TRIGGERS_MODEL);
  const [allTags, setAllTags] = useState([]);
  const [listDropdown, setListDropdown] = useState([]);
  const [listDropdownPureAd, setListDropdownPureAd] = useState([]);
  const getResponseById = async (id: string, name: string) => {
    try {
      const data = await getResponse(agent.company, id);
      setResponsesForm({ name: name, messages: data.data.messages });
      setInitialResponsesForm(data.data.messages);
    } catch {}
  };

  useEffect(() => {
    if (action == "edit") {
      // Here the edit forms
      if (tableSelected === "tags") {
        setTagsForm(selectedRow);
      } else if (tableSelected === "pure_ads") {
        setPureAdsForm(selectedRow);
      } else if (tableSelected === "other_sources") {
        setOtherSourcersForm(selectedRow);
      } else if (tableSelected === "triggers") {
        setTriggersForm(selectedRow);
      } else if (tableSelected === "impure_ads") {
        setImpureAdsForm(selectedRow);
      } else if (tableSelected === "responses") {
        getResponseById(selectedRow.id, selectedRow.name);
      }
    } else {
      if (tableSelected === "responses") {
        setResponsesForm(RESPONSES_MODEL);
      } else if (tableSelected === "tags") {
        setTagsForm(TAGS_MODEL);
      } else if (tableSelected === "pure_ads") {
        setPureAdsForm(PURE_ADS_MODEL);
      } else if (tableSelected === "other_sources") {
        setOtherSourcersForm(OTHER_SOURCERS_MODEL);
      } else if (tableSelected === "triggers") {
        setTriggersForm(TRIGGERS_MODEL);
      } else if (tableSelected === "impure_ads") {
        setImpureAdsForm(IMPURE_ADS_MODEL);
      }
    }
  }, [action, selectedRow, tableSelected]);

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    if (tableSelected === "tags") {
      setTagsForm({ ...tagsForm, [e.target.name]: e.target.value });
    } else if (tableSelected === "pure_ads") {
      setPureAdsForm({ ...pureAdsForm, [e.target.name]: e.target.value });
    } else if (tableSelected === "other_sources") {
      setOtherSourcersForm({
        ...otherSourcersForm,
        [e.target.name]: e.target.value,
      });
    } else if (tableSelected === "triggers") {
      setTriggersForm({ ...triggersForm, [e.target.name]: e.target.value });
    }
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    let formToSend: any;
    switch (tableSelected) {
      case "responses":
        formToSend = {
          ...responsesForm,
        };

        break;
      case "tags":
        formToSend = { ...tagsForm };
        break;
      case "pure_ads":
        formToSend = { ...pureAdsForm };
        break;
      case "other_sources":
        formToSend = { ...otherSourcersForm };
        break;
      case "triggers":
        formToSend = { ...triggersForm };
        break;
      case "impure_ads":
        formToSend = { ...impureAdsForm };
        break;
      default:
        console.error("Tipo de formulario no reconocido");
        return;
    }

    // Enviar el formulario
    if (tableSelected === "responses") {
      if (formToSend.name === "") {
        toast.error("Debes completar el nombre");
        return;
      }
      if (isEditMessage !== null) {
        setModalConfirmation(true);
        return;
      }
      sendResponses(formToSend);
    } else {
      actionTable(
        action,
        formToSend,
        tableSelected,
        selectedRow.id,
        agent.company
      ).then((data) => {
        tableGetData();
        closeModal(isEditMessage);
      });
    }
  };

  const sendResponses = async (formData: Response) => {
    try {
      const data = await postResponses(
        formData,
        agent.company,
        action,
        selectedRow.id
      );
      console.log();
      toast.success(data.message, {
        autoClose: 2000,
      });
    } catch {
    } finally {
      tableGetData();
      closeModal(isEditMessage);
    }
  };

  const compareArrays = (arr1: MessageResponse[], arr2: MessageResponse[]) => {
    if (arr1.length !== arr2.length) return true;
    for (let i = 0; i < arr1.length; i++) {
      if (JSON.stringify(arr1[i]) !== JSON.stringify(arr2[i])) return true;
    }
    return false;
  };

  const cancelAction = () => {
    if (tableSelected === "responses") {
      if (initialResponsesForm.length !== 0) {
        const changes = compareArrays(
          initialResponsesForm,
          responsesForm.messages
        );
        if (changes || isEditMessage !== null) {
          setModalConfirmation(true);
        }
        return;
      }
    }
    closeModal(isEditMessage);
  };

  useEffect(() => {
    localStorage.setItem("editResponses", JSON.stringify(isEditMessage));
  }, [isEditMessage]);

  useEffect(() => {
    const handleKeydown = (event: KeyboardEvent) => {
      if (event.key === "Escape" && tableSelected === "responses") {
        setModalConfirmation(true);
      }
    };

    window.addEventListener("keydown", handleKeydown, true);

    return () => {
      window.removeEventListener("keydown", handleKeydown, true);
    };
  }, []);

  return (
    <>
      <Box
        component="form"
        sx={{
          "& .MuiTextField-root": { m: 1, width: "100%" },
        }}
        noValidate
        autoComplete="off"
        onSubmit={handleSubmit}
        className="form-config-container"
      >
        <div className="w-full gap-5 flex flex-col items-center">
          <div
            className={`${
              formType == "responses" && action != "delete"
                ? "w-full"
                : "w-full"
            }`}
          >
            {action !== "delete" && (
              <>
                {formType === "responses" && (
                  <ModalContent
                    responsesForm={responsesForm}
                    setResponsesForm={setResponsesForm}
                    isEditMessage={isEditMessage}
                    setIsEditMessage={setIsEditMessage}
                  />
                )}
                {formType === "tags" && (
                  <>
                    <TextField
                      id="name"
                      label="Name"
                      name="name"
                      onChange={(e) => handleChange(e)}
                      value={tagsForm.name}
                      sx={{ marginLeft: "3px" }}
                      className="tagsTextField"
                    />
                    <TextField
                      id="color"
                      name="color"
                      label="Color"
                      onChange={(e) => handleChange(e)}
                      value={tagsForm.color}
                      className="tagsTextField"
                    />
                    <TextField
                      id="icon_name"
                      name="icon_name"
                      label="Icon name"
                      onChange={(e) => handleChange(e)}
                      value={tagsForm.icon_name}
                      className="tagsTextField"
                    />
                    <TextField
                      id="description"
                      name="description"
                      label="Descripcion"
                      onChange={(e) => handleChange(e)}
                      value={tagsForm.description}
                      className="tagsTextField"
                    />
                    <TextField
                      id="category"
                      name="category"
                      label="Category"
                      onChange={(e) => handleChange(e)}
                      value={tagsForm.category}
                      className="tagsTextField"
                    />
                  </>
                )}
                {formType === "pure_ads" && (
                  <>
                    <TextField
                      id="name"
                      label="Name"
                      name="name"
                      onChange={(e) => handleChange(e)}
                      value={pureAdsForm.name}
                    />
                    <TextField
                      id="description"
                      name="description"
                      label="Description"
                      onChange={(e) => handleChange(e)}
                      value={pureAdsForm.description}
                    />
                    <TextField
                      id="ad_id"
                      name="ad_id"
                      label="AD ID"
                      onChange={(e) => handleChange(e)}
                      value={pureAdsForm.ad_id}
                    />
                    {/* @ts-ignore */}
                    {allTags.length > 1 && (
                      <MultiSelect
                        data={allTags}
                        //@ts-ignore
                        defaultSelected={pureAdsForm.tags}
                        onchange={(value: string[]) =>
                          setPureAdsForm({
                            ...pureAdsForm,
                            //@ts-ignore
                            tags_id_and_name: value,
                          })
                        }
                      />
                    )}
                  </>
                )}
                {formType === "other_sources" && (
                  <>
                    <TextField
                      id="name"
                      label="Name"
                      name="name"
                      onChange={(e) => handleChange(e)}
                      value={otherSourcersForm.name}
                    />
                    <TextField
                      id="description"
                      name="description"
                      label="Description"
                      onChange={(e) => handleChange(e)}
                      value={otherSourcersForm.description}
                    />
                    {/* @ts-ignore */}
                    {allTags.length > 1 && (
                      <MultiSelect
                        data={allTags}
                        //@ts-ignore
                        defaultSelected={pureAdsForm.tags}
                        onchange={(value: string[]) =>
                          setOtherSourcersForm({
                            ...otherSourcersForm,
                            //@ts-ignore
                            tags_id_and_name: value,
                          })
                        }
                      />
                    )}
                  </>
                )}
                {formType === "triggers" && (
                  <>
                    <TextField
                      id="posibilidad"
                      name="posibilidad"
                      label="Posibilidad"
                      onChange={(e) => handleChange(e)}
                      value={triggersForm.posibilidad}
                    />
                    <TextField
                      id="corte"
                      name="corte"
                      label="Corte"
                      type="number"
                      onChange={(e) => handleChange(e)}
                      value={triggersForm.corte}
                    />
                    {listDropdown.length > 1 && (
                      //@ts-ignore
                      <MultiSelect
                        data={listDropdown}
                        name={"Other Sources"}
                        multiple={false}
                        disabled={
                          //@ts-ignore
                          triggersForm.pure_ad_id_and_name.id ? true : false
                        }
                        onchange={(value: valueData[]) =>
                          //@ts-ignore
                          setTriggersForm({
                            ...triggersForm,
                            other_source_id_and_name: value,
                          })
                        }
                      />
                    )}
                    {listDropdownPureAd.length > 1 && (
                      //@ts-ignore
                      <MultiSelect
                        data={listDropdownPureAd}
                        name={"Pure Ad"}
                        disabled={
                          //@ts-ignore
                          triggersForm.other_source_id_and_name.id
                            ? true
                            : false
                        }
                        multiple={false}
                        onchange={(value: valueData[]) =>
                          //@ts-ignore
                          setTriggersForm({
                            ...triggersForm,
                            pure_ad_id_and_name: value,
                          })
                        }
                      />
                    )}
                  </>
                )}
                {formType === "impure_ads" && (
                  <>
                    <TextField
                      id="url"
                      label="Url"
                      name="url"
                      onChange={(e) => handleChange(e)}
                      value={impureAdsForm.url}
                    />
                    {listDropdown.length > 1 && (
                      //@ts-ignore
                      <MultiSelect
                        multiple={false}
                        name={"Impure Ads"}
                        data={listDropdown}
                        onchange={(value: string[]) =>
                          setImpureAdsForm({
                            ...impureAdsForm,
                            //@ts-ignore
                            pure_ad_id_and_name: value,
                          })
                        }
                      />
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </div>
        <Modal.Footer className="flex justify-end" style={{ paddingBottom: 0 }}>
          <Button className="!bg-[#ff5758]" onClick={cancelAction}>
            Cancelar
          </Button>
          <Button
            className="bg-principalColor"
            type="submit"
            disabled={isEditMessage !== null}
          >
            Confirmar
          </Button>
        </Modal.Footer>
      </Box>

      {modalConfirmation && (
        <div className="modalConfirmation">
          <div className="w-[300px] px-4 py-8 flex flex-col gap-5 bg-[#212944] rounded-lg shadow-md">
            <p className="text-white text-lg font-bold">
              Tienes cambios pendientes de enviar
            </p>
            <p className="text-white ">¿Desea irse de todos modos?</p>
            <div className="flex gap-3 w-full justify-center items-center mt-2">
              <Button
                className="!bg-[#ff5758]"
                onClick={() => setModalConfirmation(false)}
              >
                Cancelar
              </Button>
              <Button
                className="bg-principalColor"
                type="submit"
                onClick={directClose}
              >
                Confirmar
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default React.memo(FormConfig);
