import { Routes, Route } from "react-router-dom";
import { AuthenticationGuard } from "./components/AuthenticationGuard";
import Login from "./pages/Login";
import Messenger from "./pages/Messenger";
import UserAuth from "./utils/UserAuth";
import Home from "./pages/Home";
import PlantillasNew from "./pages/PlantillasNew";
import Products from "./pages/Products";
// Cambio
import "moment/locale/es";
import Config from "./pages/Config";
import Analytics from "./pages/Analytics";
import Admin from "./pages/Admin";
import Detail from "./pages/Detail";
import Register from "./pages/Register";
import Workflows from "./pages/Workflows";

// Comentario para actualizar amplify
const App: React.FC = () => {
  return (
    <UserAuth>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route
          path="/home"
          element={<AuthenticationGuard component={Home} />}
        />
        <Route
          path="/inbox"
          element={<AuthenticationGuard component={Messenger} />}
        />
        <Route
          path="/plantillas"
          element={<AuthenticationGuard component={PlantillasNew} />}
        />
        <Route
          path="/config/:table"
          element={<AuthenticationGuard component={Config} />}
        />
        <Route
          path="/analytics/:table"
          element={<AuthenticationGuard component={Analytics} />}
        />
        <Route
          path="/admin"
          element={<AuthenticationGuard component={Admin} />}
        />
        <Route
          path="/products"
          element={<AuthenticationGuard component={Products} />}
        />
        <Route
          path="analytics/detail/:id"
          element={<AuthenticationGuard component={Detail} />}
        />
        <Route
          path="/register"
          element={<AuthenticationGuard component={Register} />}
        />
        <Route
          path="/workflows"
          element={<AuthenticationGuard component={Workflows} />}
        />
      </Routes>
    </UserAuth>
  );
};

export default App;
