import { Spinner } from "flowbite-react";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { GrClose } from "react-icons/gr";
import { toast } from "react-toastify";
import {
  getFastAnswers,
  getFastAnswersMessages,
} from "../../services/ChatMultimedia.service";
import { send_messages } from "../../services/ChatsActions.service";
import userContext from "../../utils/userContext";
import {
  MessageResponse,
  ModalContent,
  Response,
} from "../Responses/ModalContent";

import { BsFillSendCheckFill, BsSearch } from "react-icons/bs";
import { useClickOutside } from "../../hooks/use-click-outside";


interface ModalFastAnswersProps {
  isOpen: boolean;
  onClose: () => void;
  refetchMessages: () => void;
}

interface fastAnswerItem {
  id: string;
  name: string;
  sent: boolean;
  updated_at: string;
}

const RESPONSES_MODEL = {
  name: "",
  messages: [],
};

const getFastAnswersCall = async (company: string, conversationId: string) => {
  try {
    let data = await getFastAnswers(company, conversationId);
    console.log("data: ", data);
    return data;
  } catch (error) {
    console.error("Error fetching fast answers:", error);
    throw error;
  }
};

const getMessages = async (
  company: string,
  conversationId: string,
  fastAnswerId: string
) => {
  try {
    let data = await getFastAnswersMessages(
      company,
      conversationId,
      fastAnswerId
    );
    console.log("data: ", data);
    return data;
  } catch (error) {
    console.error("Error fetching fast answers:", error);
    throw error;
  }
};

const ModalFastAnswers: React.FC<ModalFastAnswersProps> = ({
  isOpen,
  onClose,
  refetchMessages,
}) => {
  const { clientUser, agent } = useContext<any>(userContext);

  const [responsesForm, setResponsesForm] = useState<Response>(RESPONSES_MODEL);
  const [isEditMessage, setIsEditMessage] = useState<MessageResponse | null>(
    null
  );
  const [fastAnswersList, setFastAnswersList] = useState<fastAnswerItem[]>([]);
  const [selectedItemId, setSelectedItemId] = useState<string | null>(null);
  const [loading, setLoading] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  const handleGetMessages = useCallback(async (fastAnswerId: string) => {
    try {
      const messages = await getMessages(
        agent.company,
        clientUser.conversation_id,
        fastAnswerId
      );
      setIsEditMessage(null);
      setResponsesForm(messages);
      setSelectedItemId(fastAnswerId);
    } catch (error) {
      console.error("Error fetching messages:", error);
    }
  }, [agent.company, clientUser.conversation_id]);

  useEffect(() => {
    if (isOpen) {
      getFastAnswersCall(agent.company, clientUser.conversation_id)
        .then((data) => {
          setFastAnswersList(data);
          if (data.length > 0) {
            handleGetMessages(data[0].id);
            setSelectedItemId(data[0].id);
          }
        })
        .catch((error) => {
          console.error("Error in useEffect:", error);
        });
    }
  }, [isOpen, agent.company, clientUser.conversation_id, handleGetMessages]);

  const handleSendMessages = async () => {
    setLoading(true);
    try {
      let body_to_send = {
        agent_email: agent.id,
        chat_id: clientUser.conversation_id,
        messages: responsesForm.messages,
      };

      let data = await send_messages(agent.company, body_to_send);

      toast.success(data.message);
      onClose();
      refetchMessages();
    } catch {
      toast.error("Error al enviar mensajes.");
    } finally {
      setLoading(false);
    }
  };

  const filteredAnswers = fastAnswersList.filter((item) =>
    item.name.toLowerCase().includes(searchTerm.toLowerCase())
  );

  // Modal ref
  const ref = useRef(null);

  // Handle close modal
  useClickOutside(ref, () => onClose());

  if (!isOpen) return null;

  return (
    <div
      className="fixed top-0 left-0 z-[999999] overflow-x-hidden  items-center justify-center flex bg-gray-900 bg-opacity-50 w-full h-full"
      onClick={(event) => {
        event.stopPropagation();
      }}
    >
      <div ref={ref} className="bg-[#212944] rounded-md p-6 max-h-[70vh] overflow-y-auto relative w-[90vw] flex flex-col gap-5">
        <button
          onClick={onClose}
          className="absolute right-5 top-5 z-10 p-1 rounded bg-gray-200"
        >
          <GrClose />
        </button>
        <div className="flex flex-col justify-center items-center h-[90%] w-full gap-3">
          <h3 className="text-xl font-medium text-white text-left p-2 w-full">
            Respuestas rapidas
          </h3>
          <div className="flex flex-wrap gap-3 w-full">
            <div className="w-full md:w-1/4 flex-1 overflow-hidden p-2">
              <ul className="border border-[#576080] bg-[#1a2238] rounded h-full max-h-[30dvh] md:max-h-[50dvh] overflow-y-auto">
                <div className="p-4">
                  <div className="rounded w-full flex gap-1 items-center bg-[#2a3454] text-white border border-[#576080] px-2">
                    <BsSearch className="text-white text-xl" />
                    <input
                      type="text"
                      placeholder="Buscar respuesta rápida"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                      className="w-full p-2 pr-0 border-none outline-none bg-transparent"
                    />
                  </div>
                </div>
                {filteredAnswers.map((item) => (
                  <li
                    key={item.id}
                    className={`text-lg py-4 px-6 cursor-pointer hover:bg-[#2a3454] flex items-center gap-3 ${
                      selectedItemId === item.id ? "bg-[#3a4464]" : ""
                    } 
                    ${!item.sent ? "text-white " : "text-principalColor"} 
                    `}
                    onClick={() => handleGetMessages(item.id)}
                  >
                    {item.sent && (
                      <BsFillSendCheckFill className="text-principalColor text-xl" />
                    )}
                    {item.name}
                  </li>
                ))}
              </ul>
            </div>
            <div className="w-full md:w-3/4">
              <ModalContent
                responsesForm={responsesForm}
                setResponsesForm={setResponsesForm}
                isEditMessage={isEditMessage}
                setIsEditMessage={setIsEditMessage}
                isFastAnswersInChat={true}
              />
            </div>
          </div>
        </div>
        <div className="flex gap-3 justify-end">
          <button
            className="bg-[#ff5758] rounded text-white px-6 py-2 text-sm"
            onClick={() => onClose()}
          >
            Cancelar
          </button>
          <button
            className="bg-[#20cab4] rounded text-white px-6 py-2 text-sm"
            onClick={() => handleSendMessages()}
          >
            {loading ? <Spinner /> : "Enviar ahora"}
          </button>
        </div>
      </div>
    </div>
  );
};

export { ModalFastAnswers };
