import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import App from './App';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';
import { Auth0ProviderWithNavigate } from "./auth0-provider-with-navigate";

// Reset inbox and panel search params to avoid errors
// TODO: Add cusrrent opended chat in to search params to
// allow reload the page and go bhack to the same chat.
const url = new URL(window.location as any);

if (url.searchParams.get("panel") || url.searchParams.get("conversation")) {
  url.searchParams.delete("panel");
  url.searchParams.delete("conversation");
  
  window.history.pushState({}, "", url);
}

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(

  <QueryClientProvider
    client={
      new QueryClient({
        defaultOptions: {
          queries: {
            refetchOnWindowFocus: false,
          },
        },
      })
    }
  >
    <BrowserRouter>
      <Auth0ProviderWithNavigate>
        <App />
      </Auth0ProviderWithNavigate>
    </BrowserRouter>
  </QueryClientProvider>
  // coomnetario


  
);
